import * as React from "react"
import { BlogItemsStyles } from "./BlogStyles"

const Blog = () => {
    return (
        <BlogItemsStyles>
            <h1>
                Coming Soon!
            </h1>
        </BlogItemsStyles>
    )
}

export default Blog